<template>
	<div style="min-height: 0.2rem;padding-bottom: 1rem;">
		<div data-blur="0" data-show="1" class="M1hv08Saat6oRJv0Gy45">
			<div class="OFDZaczxvoDfCgBfAY3h" data-components-name="coms-theme-1-footer"><template></template>
				<section class="LseXQWJMvDiV2XalL3sA">
					<div class="pU9uCahuI5X16Y5schiY">
						<div class="bUGFPYZ0IBtUg6TvqFnc vertival-box">
							<div class="UJY0E549iXw8BAops2og item-box">
								<div class="JxE2bCrlce_jVHNQ56UL link-list-title">Cassino</div>
								<div class="dIvaBEC9yHdlaEHxlYwe item-list">
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('event')">Eventos</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item ImbZfuNlIcC1JuS98DxH">Missão
									</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('canReceive')">Pendente</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('vip')">VIP</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item">Agente</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('rechargeFund')">Fundo de
										Previdência</div>
								</div>
							</div>
							<!-- <div class="dIvaBEC9yHdlaEHxlYwe">
								<div @click="openUrl('event')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Eventos') }}</div>
								<div class="m4LhLKEZzJw_JjBFp0PP link-list-item ImbZfuNlIcC1JuS98DxH">
									{{ $t('_home._Missão') }}</div>
								<div class="m4LhLKEZzJw_JjBFp0PP link-list-item ImbZfuNlIcC1JuS98DxH">
									{{ $t('_home._Rebate') }}</div>
								<div @click="openUrl('canReceive')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Pendente') }}</div>
								<div @click="openUrl('vip')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._VIP') }}</div>
								<div @click="openUrl('promote')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Convidar') }}</div>
								<div @click="openUrl('rechargeFund')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._FundodePrevidência') }}</div>
							</div> -->
							<div class="UJY0E549iXw8BAops2og item-box">
								<div class="JxE2bCrlce_jVHNQ56UL link-list-title">Jogos</div>
								<div class="dIvaBEC9yHdlaEHxlYwe item-list">
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item">Slots</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item">Blockchain</div>
								</div>
							</div>
							<div class="UJY0E549iXw8BAops2og item-box">
								<div class="JxE2bCrlce_jVHNQ56UL link-list-title">Suporte</div>
								<div class="dIvaBEC9yHdlaEHxlYwe item-list">
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('notice')">Suporte</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item" @click="openUrl('notice')">Central de Ajuda</div>
									<div class="m4LhLKEZzJw_JjBFp0PP link-list-item">Bônus de Sugestão</div>
								</div>
							</div>
						</div>
						<div class="mmVblCO1C2DkoUeKEY2w">
							<div class="M9sYhkFFQiFmM32ifMp5 channel-box">
								<div class="u_ecgPCLkRaUsesukqAW"> Canal Oficial</div>
								<div class="BCZ4CKkRqkon2n_mgG1Q">
									<img v-if="$store.state.config.cnpj_link != '@'" @click="openUrlCsusy(config.cnpj_link)"
										src="./../../../assets/kuaijiexiaz/cnpj.png"
										class="goDRiiBsuEuXD3W1NphN UFAFwugOhgpx7Zb7eUTn channel-item">
									<img @click="openUrlCsusy(config.instagram)"
										src="./../../../assets/kuaijiexiaz/1795519195972894722.png"
										class="goDRiiBsuEuXD3W1NphN UFAFwugOhgpx7Zb7eUTn channel-item">
									<img @click="openUrlCsusy(config.whatsapp)"
										src="./../../../assets/kuaijiexiaz/img_wa.png"
										class="goDRiiBsuEuXD3W1NphN UFAFwugOhgpx7Zb7eUTn channel-item">
								</div>
							</div>
							
						</div>
						<div class="company-info">
							<div class="yIl5wRiijLc7UrecEbyO infoArea">
								<div class="PtU3fLkIewRcrUlhRYyd text">
									<p>{{config.explain_description}}</p>
								</div>
							</div>
						</div>
						<div class="TNhhMltbvlGJmWgLd9F5 logo-area">
							<div class="QP1fgnfNl3ak5aLI38nQ logo-link" style="height: 0.38rem;"><img
									data-groups-id="my-img-b53b8d07-2e44-4daa-ad9d-20437600590c"
									data-src-render="https://sweykpro.pubs3static.com/siteadmin/template/img_footer_jdb.png"
									src="https://sweykpro.pubs3static.com/siteadmin/template/img_footer_jdb.png"
									alt="." data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN I4r5jH_hdzbxrjf7nkFg"></div>
							<div class="QP1fgnfNl3ak5aLI38nQ logo-link" style="height: 0.38rem;"><img
									data-groups-id="my-img-4f83cd5d-5870-475e-ae65-4ffcac92690e"
									data-src-render="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779199047662415874.avif"
									src="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779199047662415874.avif"
									alt="." data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN I4r5jH_hdzbxrjf7nkFg"></div>
							<div class="QP1fgnfNl3ak5aLI38nQ logo-link" style="height: 0.38rem;"><img
									data-groups-id="my-img-f864e568-75f8-4e5d-b0b6-e41bcbd2cbfc"
									data-src-render="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779198967524442113.avif"
									src="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779198967524442113.avif"
									alt="." data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN I4r5jH_hdzbxrjf7nkFg"></div>
							<div class="QP1fgnfNl3ak5aLI38nQ logo-link" style="height: 0.38rem;"><img
									data-groups-id="my-img-b8d8e2bd-1468-42ff-a351-ba71d9b671e3"
									data-src-render="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779198998624444418.avif"
									src="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779198998624444418.avif"
									alt="." data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN I4r5jH_hdzbxrjf7nkFg"></div>
							<div class="QP1fgnfNl3ak5aLI38nQ logo-link" style="height: 0.38rem;"><img
									data-groups-id="my-img-28bf172e-7968-4abe-84a9-562d93bf67be"
									data-src-render="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779199131156824065.avif"
									src="https://cdntoos.vr6pg.com/siteadmin/upload/img/1779199131156824065.avif"
									alt="." data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN I4r5jH_hdzbxrjf7nkFg"></div>
						</div><template></template><template></template>
					</div>
				</section>
			</div>
		</div>
	</div>
	
</template>

<script>
	import { mapState } from 'vuex';
	import APopup from "@/components/Idioma-popup/index.vue";
	export default {
		name: 'records',
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config'])
		},
		methods:{
			openUrlCsusy(e){
				if(e!='#'){
					window.open(e, '_blank');
				}
			}
		}
	}
	
</script>

<style scoped>
	.vertival-box:after{
		border-bottom: none;
		height: 0 !important;
	}
	.vertival-box{
		padding-bottom: 0;
	}
	.TNhhMltbvlGJmWgLd9F5 {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    gap: .15rem;
	    justify-content: center;
	    margin-top: .4rem
	}
	
	.TNhhMltbvlGJmWgLd9F5 .QP1fgnfNl3ak5aLI38nQ .I4r5jH_hdzbxrjf7nkFg {
	    height: 100%
	}
	
	.TNhhMltbvlGJmWgLd9F5 .utwehnHMr2EwsIo5BpHh {
	    height: auto!important
	}
	.mmVblCO1C2DkoUeKEY2w {
	    display: -ms-flexbox;
	    display: flex;
	    position: relative
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w .teae_NnPrM2iLkzhOMBf {
	    -ms-flex: 1;
	    flex: 1;
	    overflow: hidden;
	    padding-right: .1rem
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w .teae_NnPrM2iLkzhOMBf .bqDYlJVo_WYcY76yRl6u {
	    color: var(--theme-text-color-lighten);
	    font-size: .24rem;
	    margin-bottom: .2rem
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w .teae_NnPrM2iLkzhOMBf .REDtSdf0w9HK2n0VYpm0 {
	    color: var(--theme-text-color);
	    font-size: .24rem;
	    margin-bottom: .1rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w:after {
	    background-color: var(--theme-color-line);
	    bottom: 0;
	    content: "";
	    height: .01rem;
	    left: 50%;
	    position: absolute;
	    -webkit-transform: translateX(-50%);
	    transform: translateX(-50%);
	    width: 100%
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w .fast-share-box {
	    -ms-flex: 1;
	    flex: 1
	}
	
	.pU9uCahuI5X16Y5schiY .mmVblCO1C2DkoUeKEY2w .channel-box {
	    -ms-flex: 2;
	    flex: 2
	}
	.M9sYhkFFQiFmM32ifMp5 {
	    margin-bottom: .3rem;
	    width: 100%
	}
	
	.M9sYhkFFQiFmM32ifMp5 .u_ecgPCLkRaUsesukqAW {
	    color: var(--theme-text-color-lighten);
	    font-size: .24rem;
	    margin-bottom: .18rem
	}
	
	.M9sYhkFFQiFmM32ifMp5 .BCZ4CKkRqkon2n_mgG1Q {
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    gap: .2rem
	}
	
	.M9sYhkFFQiFmM32ifMp5 .BCZ4CKkRqkon2n_mgG1Q .UFAFwugOhgpx7Zb7eUTn {
	    height: .7rem;
	    width: .7rem
	}

</style>