import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);

// 需要永久存储，且下次APP启动需要取出的，在state中的变量名
let saveStateKeys = ['vuex_token','xiazApp','vuexr_user', 'vuex_token_head', "vx_invite_code", 'game_url', '_i18nLocale', 'v_tabs_id',
	'v_tab_id','rechargeCpf'
];

// 保存变量到本地存储中
const saveLifeData = function(key, value) {
	// 判断变量名是否在需要存储的数组中
	if (saveStateKeys.indexOf(key) != -1) {
		// 获取本地存储的lifeData对象，将变量添加到对象中
		let tmp = localStorage.getItem('lifeData');
		// 第一次打开APP，不存在lifeData变量，故放一个{}空对象
		tmp = tmp ? JSON.parse(tmp) : {};
		tmp[key] = value;
		// 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
		localStorage.setItem('lifeData', JSON.stringify(tmp));
	}
}

 
const state = {
  count: 0,
  showPopup:false,
  active:0,
  IsHeaderTab:false,
  tabBer:true,
  _i18nLocale:'pt',
  config:{},
  isCancelDisplay:true,
  vuex_token:'',
  vuex_token_head:'',
  overlayShow:false,
  CurrentPlayback:{},
  songSheetList:[],
  myMusic:[],
  myMusicList:[],
  popList:[],
  userInfo:{},
  rechargeCpf:{},
  PaymentInformation:{},
  isPayment:false,
  isRecord:false,
  isRecordType:0,
  sLoading:false,
  isDeposit:false,
  moneyInfo:{},
  xiazApp:true,
  AppDownload:false,
  Returntotop:false,
  tabNav:{},//
  banner:{},
  sidebarsList:{},
  marquee:'',
  antSelectModel:{
	  
  },
  isDireto:{
	  isDireto:false,
	  LinkdeConviteEvent:false,
	  DetalhesdaAposta:false,
  },
  DetalhesdaAposta:{
	  
  },
  shareLinkInfo:{
    id: -1,
    link: 'none',
  },
};
 
const mutations = {
  increment(state) {
    state.count++;
  },
  DetalhesdaAposta(state,valueKey){
	   console.log(valueKey)
	   state[valueKey[0]]=valueKey[1];
  },
  isDeposit(state,value){
  	   state.isDeposit=typeof value === 'boolean'?value:!state.isDeposit;
  },
  publicFun(state,valueKey){
		console.log(valueKey)
	  state[valueKey[0]]=valueKey[1];
  },
  sLoading(state,value){
  	   state.sLoading=typeof value === 'boolean'?value:!state.sLoading;
  },
  popList(state,value){
	   state.popList=value;
  },
  isRecord(state,value){
	   state.isRecord=value||!state.isRecord;
  },
  myMusicList(state,value){
  	  state.myMusicList=value;
  },
  CurrentPlayback(state,value){
	  state.CurrentPlayback=value;
  },
  songSheetList(state,value){
  	  state.songSheetList=value;
  },
  myMusic(state,value){
  	  state.myMusic=value;
  },
  overlayShow(state){
	   state.overlayShow=!state.overlayShow;
  },
  showPopup(state){
	  console.log(state.showPopup);
    state.showPopup=!state.showPopup;
  },
  active(state,e){
    state.active=e;
  },
  $emit(state,e){
	  state.active=e;
  },
  IsHeaderTab(state,e){
    state.IsHeaderTab=e;
  },
  tabBer(state,e){
	  state.tabBer=e;
  },
  config(state,e){
  	  state.config=e;
  },
  userInfo(state,e){
	  state.userInfo=e;
  },
  rechargeCpf(state,e){
	  state.rechargeCpf=e;
  },
  $uStore(state, payload) {
  	// 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
  	let nameArr = payload.name.split('.');
  	let saveKey = '';
  	let len = nameArr.length;
  	if (nameArr.length >= 2) {
  		let obj = state[nameArr[0]];
  		for (let i = 1; i < len - 1; i++) {
  			obj = obj[nameArr[i]];
  		}
  		obj[nameArr[len - 1]] = payload.value;
  		saveKey = nameArr[0];
  	} else {
  		// 单层级变量，在state就是一个普通变量的情况
  		state[payload.name] = payload.value;
  		saveKey = payload.name;
  	}
  	// 保存变量到本地，见顶部函数定义
  	saveLifeData(saveKey, state[saveKey])
  }
};
 
const actions = {
  increment({ commit }) {
    commit('increment');
  },
  active({ commit }) {
    commit('active');
  }
};
const getters = {
  count: state => state.count,
};
const store= new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
// 从缓存中取得相同的key进行覆盖操作
let lifeData =localStorage.getItem('lifeData') || {};
lifeData= typeof lifeData =="string"? JSON.parse(lifeData):lifeData
for (let key in lifeData) {
	if (store.state.hasOwnProperty(key)) {
		store.commit('$uStore', {
			name: key,
			value: lifeData[key]
		})
	}
}

 
export default store