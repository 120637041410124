<template>
	<div>
		<div class="uNY2u87Ih6yCf2fiwE1Q">
			<div class="tabsWarp"> 
				<div v-for="(item,index) in popList"  :class="{
					'common-tab-item-active':tabIndex==index
				}" 
				@click="tabIndex=index"
				class="common-tab-item" style="display: flex;">
					<svg-icon className="imageWrap_s"
						style="width: .28rem;height: .28rem;"
						v-if="item.content"
						icon-class="icon_message_tz--svgSprite:all" />
					<svg-icon className="imageWrap_s"
						v-if="!item.content"
						style="width: .28rem;height: .28rem;"
						icon-class="icon_message_pmd--svgSprite:all" />
						<p class="DrmJS14Na6dEK53vAAQX">{{item.title}}</p>
				</div>
			
			</div>
			<div class="common-tabs-content" @click="openUrl(popList[tabIndex])">
				<div v-if="tabIndex" @click="tabIndex=tabIndex-1" class="_fhJToJ9pBZeoyjqe5a7 Zl2ywfksX9ooF1aQ02T9">
					<svg-icon className="imageWrap_s"
						style="width: .22rem;height: .22rem;"
						icon-class="comm_icon_fh--svgSprite:all" />
				</div>
				
				<div v-if="tabIndex!=popList.length-1" @click="tabIndex=tabIndex+1" class="mHrSCgmC4tq6FJbB8Ak3 Zl2ywfksX9ooF1aQ02T9" style="transform: rotate(180deg);">
					<svg-icon className="imageWrap_s"
						style="width: .22rem;height: .22rem;"
						icon-class="comm_icon_fh--svgSprite:all" />
				</div>
				
				<div style=" overflow: auto;height: 100%;" v-if="popList[tabIndex]">
					<div v-if="popList[tabIndex].content">
						<div class="vinlOtU5EHTmNDU9ECDE">
							{{popList[tabIndex].title}}
						</div>
						<p v-html="popList[tabIndex].content"></p>
					</div>
					<div v-else>
						<img class="common-tabs-img" v-lazy="popList[tabIndex].image" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="w17XHHAxCcKClzGm6mQu">
			<div class="WTY15hpGrP99ziiNSdSp">
				<van-checkbox v-model="typeInit2" @change="vanCheckbox" shape="square" checked-color="var(--theme-secondary-color-success)"
					icon-size=".32rem">
					<span class="chen_texbt" :style="{
						color:'#fff'
					}">Não mostrar novamente hoje</span>
				</van-checkbox>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			popList:{
				type:[Object,Array],
				required:[],
			},
		},
		data() {
			return {
				codeValue: '',
				inputFocus: false,
				typeInit2:false,
				tabIndex:0,
			};
		},
		methods: {
			vanCheckbox(e){
				this.$emit('vanCheckbox',e)
			},
			openUrl(e){
				if(e.jump_url && e.jump_url!='#'){
					if(/^http/.test(e.jump_url)){
						window.open(e.jump_url, '_blank');
						return false;
					}
					this.$router.push({name:e.jump_url})
				}
				// if(e.jump_url && e.jump_url!='#'){
				// 	this.$router.push({name:e.jump_url})
				// }
			}
		},
	}
</script>
<style>
	.login-body{
		font-weight: 400;
	}
	.van-popup.advertisement_-conter{
		background-color: transparent; 
	}
	.w17XHHAxCcKClzGm6mQu .van-checkbox__icon .van-icon{
		border-radius: .06rem;
	}
</style>
<style scoped>
	.w17XHHAxCcKClzGm6mQu {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    margin-top: .2rem;
	    width: 100%
	}
	
	 .WTY15hpGrP99ziiNSdSp {
	    -ms-flex-align: center;
	    align-items: center;
	    background-color: rgba(0,0,0,.2);
	    border-radius: .1rem;
	    bottom: .34rem;
	    color: #fff;
	    display: -ms-flexbox;
		color: #fff;
	    display: flex;
	    font-size: .18rem;
	    left: 50%;
		font-size: .22rem;
	    padding: .1rem .15rem .1rem .1rem
	}
	.Zl2ywfksX9ooF1aQ02T9 {
	    font-size: .22rem;
	    height: .44rem;
	    width: .44rem;
	}
	._fhJToJ9pBZeoyjqe5a7{
		left: .08rem;
	}
	.mHrSCgmC4tq6FJbB8Ak3{
		right: .08rem;
	}
	.Zl2ywfksX9ooF1aQ02T9{
		 -ms-flex-line-pack: center;
		    -ms-flex-pack: center;
		    align-content: center;
		    background: rgba(0,0,0,.2);
		    border-radius: 50%;
		    color: #fff;
		    cursor: pointer;
		    display: -ms-flexbox;
		    display: flex;
			align-items: center;
		    font-size: .18rem;
		    height: .36rem;
		    justify-content: center;
		    position: absolute;
		    top: 48%;
		    width: .36rem;
		    z-index: 1
	}
	.vinlOtU5EHTmNDU9ECDE{
		max-width: 90%;
		    line-height: 1.38;
		    margin: 0 auto .2rem;
			    font-size: .3rem;
				    color: var(--theme-text-color-darken);
					text-align: center;
	}
	.common-tab-item-active {
	    background-color: rgba(var(--theme-primay-color-hex),.05)
	}
	.uNY2u87Ih6yCf2fiwE1Q{
		height: 4.2rem;
		display: flex;
		    border: .01rem solid var(--theme-color-line);
		    border-radius: .2rem;
		    background-color: var(--theme-main-bg-color);
		    position: relative;
	}
	.common-tab-item{
		display: flex;
		align-items: center;
		    height: .8rem !important;
			box-sizing: border-box;
		    padding: .1rem .15rem;
		    text-align: revert !important;
	}
	.common-tab-item .imageWrap_s{
		    color: var(--theme-text-color-lighten);
			    margin-right: .08rem;
	}
	.DrmJS14Na6dEK53vAAQX{
		    color: var(--theme-primary-color);
			    flex: 1;
			    font-size: .22rem;
			    line-height: .28rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
				    -webkit-box-orient: vertical;
				    -webkit-line-clamp: 2;
				    display: -webkit-box;
				    font-size: .22rem;
				    line-height: .22rem;
				    overflow: hidden;
				    white-space: break-spaces !important;
	}
	.common-tabs-content{
		width: 5.28rem;
		font-size: .23rem;
		line-height: 1.64;
		box-sizing: border-box;
		padding: .2rem;
		   
		    position: relative;
		    text-align: left;
	}
	.common-tabs-img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.tabsWarp{
		    border-right: thin solid var(--theme-color-line);
			width: 1.62rem;
	}
	.advertisement_list{
		    width: 6.9rem;
			background-color: var(--theme-main-bg-color);
			border-radius: .2rem;
	}
	.advertisement_list .title{
		    color: var(--theme-text-color-darken);
			text-align: center;
			line-height: 1rem;
			font-size: .4rem;
	}
	._common-tabs-content{
		max-height: 5.4rem;
		width: 100%;
	}
	.consliTst{
		display: flex;
		align-items: center;
		border-radius: .1rem;
		height: .8rem !important;
		background-color: rgba(var(--theme-primay-color-hex), .05);
		max-width: 2.05rem;
		padding: .15rem;
		text-align: revert !important;
	}
	.consliTst svg{
		width: .38rem;
		height: .38rem;
		color: var(--theme-primary-color);
		    margin-right: .08rem;
	}
	.consliTst span{
		    font-size: .22rem;
			display: inline-block;
		    line-height: .28rem;
			flex: 1;
			    color: var(--theme-primary-color);
		    overflow: hidden;
		    text-overflow: ellipsis;
		    /* white-space: nowrap; */
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
				white-space: break-spaces !important;
	}
</style>