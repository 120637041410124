<template>
	<div class="_6pERT6w0EjQXtcqIRAJ6">
		<div class="xpAzxCHtrxQC1LOTwf8j">
			<p class="uzsnnIcKiQOiodZq3PFe">
				
				<span style="display: flex;display: inline-block;" @click="animate">{{ $t('withdraw._Vocêtambém') }}
				{{tabList.total}}
				{{$t('withdraw._VocêtambémRight')}}
					<svg-icon className="tenSlis" icon-class="comm_icon_retry--svgSprite:all" />
				</span>
			</p>
		</div>
		<div class="my-scrollbar van-dropdown-menu-box">
			<van-dropdown-menu @change="qingqList" style="min-width: 1.1rem;">
				<van-dropdown-item @change="qingqList" v-model="timValue" :options="timOption" />
			</van-dropdown-menu>
			<!-- <ant-select :value="timValue" :options="timOption" @change="qingqList">
			</ant-select> -->
		</div>
		<div style="padding: 0 .4rem;    height: calc(var(--vh, 1vh)* 100 - 2.55rem);overflow: scroll;">
			<!-- audit-details -->
			<div style="height: 100%;width: 100%;;display: flex;align-items: center;justify-content: center;" v-if="!tabList.list.length">
				<van-empty image-size="2.1rem" class="custom-image"
					:image="img_none_sj"
					
					:description="$t('description')" />
			</div>
			<div class="vW1xXcZLHWlWyn3iuUvS" v-for="(item,index) in tabList.list" :key='index' @click="$router.push({name:'audit-details',query:{
					content:JSON.stringify(item)
				}})"
			>
				<div class="kfVtikelX4uFQ5xZ6nGQ">
					<p>{{ $t('withdraw._Adicionarponto') }}<span class="_9SZPpVg4KpuaNxtF9WA ilYKnymjjjuvpc4y21DN sebZhrzrnJxsa9Sp5ZEf">+
							{{item.money}}</span></p>
					<p>x<span class="rcotCxDJ7Y4o3rXq_icU">{{item.multiple}}</span>{{ $t('withdraw._Vezes') }}</p>
					<p>
						<svg-icon className="tenSlis" icon-class="icon_ly_cz--svgSprite:all" />
						<span class="YoiO3gxQzLkkD7YlHiFH">{{ $t('withdraw._fonte') }}:</span><span
							class="ksVo8zGmQjOXnmPM1AMA k2_x6DM77ydgJiNbf_dp">Depósito</span></p>
							
				</div>
				<div class="FTvdjIUiZm548IwI_Dr_">
					<div>
						<p class="K77SrMRwm98u2q5LO4c7">{{ $t('withdraw._Apostaválidanecessária') }}<span>{{item.valid_bet}}</span></p>
						<p class="ilYKnymjjjuvpc4y21DN">
							<span class="ksVo8zGmQjOXnmPM1AMA k2_x6DM77ydgJiNbf_dp" v-if="item.status=='1'" >
								Aguardando apostas
							</span>
							<span class="pRRvAqiB8XjLf1Zi2vZR" v-if="item.status=='2'" >
								Têm em mão
							</span>
							<span class="mwx4K1DUI_ZKHLPGQNUp" v-if="item.status=='3'" >
								Completado
							</span>
							<span class="d0eJbdoCsvr1QKEtJMmt" v-if="item.status=='4'" >
								Abandonado
							</span>
							<!-- 
							têm em mão
							
							.d0eJbdoCsvr1QKEtJMmt {
							    color: var(--theme-text-color-darken)!important
							}
							
							.pRRvAqiB8XjLf1Zi2vZR {
							    color: var(--theme-secondary-color-finance)!important
							}
							
							.mwx4K1DUI_ZKHLPGQNUp {
							    color: var(--theme-secondary-color-success)!important
							}
							 {{item.status=='1'?'':''}}
							 {{item.status=='2'?'Retirada':''}}
							 {{item.status=='3'?'Completado':''}}
							 {{item.status=='4'?'Rejeitada':''}} -
							 -->
						</p>
						<p>{{item.create_time}}</p>
					</div>
					<!-- Retirada, 2: Retirada, 3: Rejeitada
					 1待投注，2进行中，3已完成，4已放弃
					 -->
					<p class="mmoR2XIEhgee6sBiL62L">
						<svg-icon className="tenSlis" icon-class="comm_icon_fh--svgSprite:all" />
					</p>
				</div>
			</div>
		</div>
		<!-- .van-cell--clickable:active -->
		

	</div>
</template>

<script>
	import {
		tim_q,
		timValue_q
	} from "@/utils/tim.js"
	import {
		getBetTasks,
	} from "@/apis/modules/login.js"
	export default {
		data() {
			return {
				timValue: timValue_q,
				timOption: tim_q,
				tabList:[],
				//Todos os Tipos     Troca de Fundos     Recarga de Membro     Saque de Membro     Liquidação do Comerciante     Correção de Fundo     Eventos     Rebate     Reembolso     Juros     Missão     Recompensa VIP     Oferta de Depósito     Clube     Auditoria em Segundo Plano     Fundo de
				// Previdência     Detalhes da Subcategoria     
			};
		},
		mounted() {
			
			// this.timOption=[...tim_q];
			this.timOption[0].text='Tudo'
			let {current}=this.$route.query;
			if(current==1){
				this.getBetTasks();
			}
		},
		methods: {
			qingqList(e){
				this.timValue=e[0];
				this.getBetTasks()
			},
			getBetTasks(){
					getBetTasks({
						type:this.timValue,
					}).then(({code,msg,data})=>{
						if(code==0){
							// data.list[0].status=1
							this.tabList=data;
						}
					})
			},
			animate(e) {
				this.getBetTasks();
				this.$attrs.animate(e)
			}
		}
	};
</script>

<style>
</style>
<style scoped>
	.my-scrollbar {
		display: flex;
		padding: .2rem;
	}
	.vW1xXcZLHWlWyn3iuUvS:nth-child(odd) {
		border-radius: .1rem;
		    padding: .2rem;
			margin-bottom: .2rem;
	    background-color: var(--theme-main-bg-color)
	}
	
	.vW1xXcZLHWlWyn3iuUvS:nth-child(2n) {
	    background-color: var(--theme-bg-color);
		border-radius: .1rem;
		    padding: .2rem;
			margin-bottom: .2rem;
	}
	.vW1xXcZLHWlWyn3iuUvS {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .22rem;
	    justify-content: space-between;
	    padding: .15rem .2rem
	}
	.vW1xXcZLHWlWyn3iuUvS .ksVo8zGmQjOXnmPM1AMA {
	    color: var(--theme-text-color-darken)
	}
	
	.vW1xXcZLHWlWyn3iuUvS .ilYKnymjjjuvpc4y21DN {
	    color: var(--theme-text-color-darken);
	    font-weight: 700
	}
	
	.vW1xXcZLHWlWyn3iuUvS .YMjUkU3H3gf01zMhZNqt {
	    color: var(--theme-secondary-color-finance)
	}
	
	.vW1xXcZLHWlWyn3iuUvS .sebZhrzrnJxsa9Sp5ZEf {
	    text-decoration: underline
	}
	
	.vW1xXcZLHWlWyn3iuUvS .qAf2zxg4tRCq86pRv9Z5 {
	    color: var(--theme-primary-color)
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ {
	    -ms-flex-item-align: stretch;
	    -ms-flex-pack: justify;
	    align-self: stretch;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    justify-content: space-between
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p {
	    -ms-flex-align: center;
	    align-items: center;
	    color: var(--theme-text-color-lighten);
	    display: -ms-flexbox;
	    display: flex;
	    margin-top: .06rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p:first-child {
	    margin-top: 0
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p ._9SZPpVg4KpuaNxtF9WA {
	    margin-left: .05rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p .rcotCxDJ7Y4o3rXq_icU {
	    color: var(--theme-text-color)
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p .YoiO3gxQzLkkD7YlHiFH {
	    margin: 0 .05rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p>i {
	    font-size: .35rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .kfVtikelX4uFQ5xZ6nGQ>p .k2_x6DM77ydgJiNbf_dp {
	    max-width: 2rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_ {
	    -ms-flex-align: center;
	    -ms-flex-item-align: stretch;
	    align-items: center;
	    align-self: stretch;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_>div {
	    -ms-flex-item-align: stretch;
	    -ms-flex-pack: justify;
	    align-self: stretch;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    justify-content: space-between;
	    text-align: right
	}
	.d0eJbdoCsvr1QKEtJMmt {
	    /* color: var(--theme-text-color-darken)!important */
		color: var(--theme-secondary-color-error)
	}
	
	.pRRvAqiB8XjLf1Zi2vZR {
	    color: var(--theme-secondary-color-finance)!important
	}
	
	.mwx4K1DUI_ZKHLPGQNUp {
	    color: var(--theme-secondary-color-success)!important
	}
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_>div>p {
	    color: var(--theme-text-color-lighten);
	    margin-top: .06rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_>div>p:first-child {
	    margin-top: 0;
	    max-width: 3rem
	}
	
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_ .K77SrMRwm98u2q5LO4c7 span {
	    color: var(--theme-text-color-darken)
	}
	
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_ .mmoR2XIEhgee6sBiL62L {
	    color: var(--theme-color-line);
	    font-size: .26rem;
	    margin-left: .15rem;
	    -webkit-transform: rotate(180deg);
	    transform: rotate(180deg)
	}
	.vW1xXcZLHWlWyn3iuUvS .FTvdjIUiZm548IwI_Dr_ .mmoR2XIEhgee6sBiL62L svg{
		width: .26rem;
		height: .26rem;
	}
	.mwx4K1DUI_ZKHLPGQNUp {
	    color: var(--theme-secondary-color-success)!important
	}
	.xpAzxCHtrxQC1LOTwf8j {
		background-color: var(--theme-main-bg-color);
		height: auto;
		justify-content: space-between;
		padding: .2rem;
		width: 100%;
		box-sizing: border-box;
	}

	.uzsnnIcKiQOiodZq3PFe {
		color: var(--theme-secondary-color-error);
		padding: 0 .2rem;
		font-size: .22rem;
		text-align: center;
		width: 100%
	}

	.uzsnnIcKiQOiodZq3PFe .tenSlis {
		width: .22rem;
		height: .22rem;
	}

	.pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}

	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		margin: 0;
		width: 50%
	}

	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl>span {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		margin: 0;
		overflow: hidden;
		padding-right: .05rem;
		text-overflow: ellipsis;
		vertical-align: middle;
		width: 50%;
		word-break: break-all
	}

	.pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		color: var(--theme-text-color);
		font-size: .22rem;
		font-weight: 200;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}

	.RjCX53sI34q23D2TwPWl>span:last-child {
		padding-left: .2rem;
		padding-right: 0
	}

	.pBg296B2aLWiyWbbg5An .HGHvRuWIBhpGiBhkEAHu {
		color: var(--theme-text-color-darken);
	}

	.pBg296B2aLWiyWbbg5An .HNKlmlfGsE25ksqykrVs {
		color: var(--theme-secondary-color-error);
	}

	.pBg296B2aLWiyWbbg5An .VsQlwP6H52Vyv4bEeq1q {
		color: var(--theme-secondary-color-success);
	}

	.pBg296B2aLWiyWbbg5An .kfrOXvCeC3B_UykAtxE_ {
		color: var(--theme-secondary-color-error);
	}

	.pBg296B2aLWiyWbbg5An .cVihnbOVCk0AV6cIk86g {
		color: var(--theme-secondary-color-finance);
	}
</style>
