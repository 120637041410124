import http from './../http.interceptor.js';
 

export function baseConfig(params) {
	return http.post('/v3.home/baseConfig', {
		isLOgin:true,
		...params
	})
}


export function songSheet(params) { // 
	return http.get('/v3.home/songSheet', {
		isLOgin:true,
		...params
	})
}
//game
export function favoriteGame(params) { // 
	return http.post('/v3.game/favoriteGame', params)
}

export function popAndBanner(params) { // 
	return http.get('/v3.home/popAndBanner', {
		isLOgin:true,
		...params
	})
}

export function notification(params) { // 
	return http.get('/v3.home/bulletins', {
		isLOgin:true,
		...params
	})
}

export function jackpot(params) { // 
	return http.get('/v3.home/jackpot', {
		isLOgin:true,
		...params
	})
}

export function getGameList(params) { // 
	return http.post('/v3.home/getGameList', {
		isLOgin:true,
		...params
	})
}

export function getAllGameCategory(params) { // 
	return http.post('/v3.home/getAllGameCategory', {
		isLOgin:true,
		...params
	})
}


export function getDataByGameCategory(params) { // 
	return http.post('/v3.home/getDataByGameCategory', {
		isLOgin:true,
		...params
	})
}

export function userInfo(params) { // 
	return http.post('/v3.user/userInfo', {
		isLOgin:true,
		...params
	})
}



export function updatePersonalData(params) { // 
	return http.post('/v3.user/updatePersonalData', params)
}



export function getBalance(params) {
	return http.post('/v3.user/getBalance', {
		isLOgin:true,
		...params
	})
}

export function userMessage(params) {
	return http.post('/v3.user/userMessage', {
		isLOgin:true,
		...params
	})
}
