<template>
	<div class="">
		<el-select v-model="value" 
		@change="onChange"
		@focus="focus"
		@blur="blur"
		:class="{
			'menu-active':isOPen
		}"
		:style="{
			width:styleWidth
		}" placeholder="">
		
		<el-option
		  v-for="item in options"
		  :key="item.value"
		  :label="item.text"
		  :value="item.value">
		</el-option>
	  </el-select>
	</div>
</template>

<script>

	export default {
		props: {
			value:{
				type: [Number,String],
				default:()=>{
					return 0
				},
			},
			options:{
				type:[Object,Array],
				default:()=>{
					return []
				}
			},
			className:{
				type: String,
				required: '',
			},
		},
		components: {
		},

		data() {
			return {
				styleWidth:'1rem',
				isOPen:false,
			}
		},
		watch: {
			// 监听message数据的变化
			value(newValue, oldValue) {
				 console.log(oldValue);
			  let item=this.options.filter(item=>{
			  	return item.value==newValue;
			  })
			  let {text}=item[0];
			  this.styleWidth=(text.length*0.13)+0.4+'rem'
			 
			  //console.log(`message changed from ${oldValue} to ${newValue}`);
			}
		  },
		mounted() {
			let item=this.options.filter(item=>{
				return item.value==this.value;
			})
			let {text}=item[0];
			this.styleWidth=(text.length*0.13)+0.4+'rem'
		},
		filters:{
			styleName(value){
				
				return text.length*0.2+'rem'
			}
		},
		computed:{
			// styleName(){
			// 	// timValue
			// 	let item=this.options=tim.filter(item=>{
			// 		return item.value==this.value
			// 	})
			// 	let {text,value}=item;
			// 	console.log(text);
			// 	return '1rem'
			// }
		},
		methods: {
			onChange(){
				this.$emit("change",arguments)
			},
			focus(){
				this.isOPen=true;
			},
			blur(){
				this.isOPen=false;
			}
		},
	}
</script>
<style>
.popper__arrow{display: none !important;}
.el-select-dropdown{
	    background-color: var(--theme-main-bg-color);
		    border: .01rem solid var(--theme-color-line)!important;
		    border-radius: .1rem;
		    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.1);
		    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.1);
		    -webkit-box-sizing: border-box;
		    box-sizing: border-box;
			max-width: 3.15rem;
			    min-width: 1.6rem;
		    overflow: hidden
}
.el-select-dropdown__list{
	    padding: .14rem 0;
}
.el-select-dropdown__item{
    font-size: .24rem;
    /* line-height: .8rem; */
	line-height: initial;
	display: flex;
	align-items: center;
    min-height: .8rem;
	 white-space: normal;
    padding: 0 .2rem;
	color: var(--theme-text-color-lighten);
}
html .el-select-dropdown__item.selected {
    background-color: transparent;
    background-color: initial;
    color: var(--theme-primary-color);
	   
    font-weight: 400
}
.el-popper{
	margin-top: .1rem;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
	 background-color: var(--theme-main-bg-color);
}
</style>
<style scoped>
	.menu-active{
		border: 0.01rem solid var(--theme-primary-color) !important;
	}
	/* border: 0.01rem solid var(--theme-primary-color) !important; */
</style>
