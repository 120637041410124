<template>
	<div class="maxMaxth home-scrollbar"  :style="{
		'background-image': `url(${config.background_image})`
		
	}" >
		<advertisementHome v-if="popList.length"  @CancelDisplay="CancelDisplay"
			:popList="popList" :isShow="isCancelDisplay"></advertisementHome>
		<sidebars :sidebarsList="sidebarsList"> </sidebars>
		<Header></Header>
		<div class="conterTeng-scrollbar" 
		ref="homeScrollbar"
		@scroll="handleScroll"
		:style="{
			height:`calc(100vh - ${$store.state.xiazApp?.9+.7:.9}rem)`
		}">
			<!-- :offset-top="`${$store.state.xiazApp?.9+.7:.9}rem`" -->
			<Swipe :swipeList="banner"></Swipe>
			<div class="notice-bar" >
				<!-- notice -->
				<van-notice-bar class="marquee" style="height: 20px;" :left-icon="tongzhi" :scrollable="true">
					<div style="display: flex;align-items: center;">
						<div v-for="(item,index) in marquee" @click="openUrl('notice',{active:3,marqueeIndex:index}) " v-html="item"></div>
					</div>
				</van-notice-bar>
				<i
					:style="`margin-left:5px;display: inline-block; position: relative; width: 0.5rem; height: 0.4375rem; background-image: url(${_coloImg}); background-position: -5.3375rem -4.275rem; background-size: 9.075rem 8.85625rem;`"></i>
			</div>
			<div class="zhtDEqmqr6aOwFvu_UTp" v-if="$store.state.config.show_jackpot">
				<div class="_VQdc8vOEOzzyOEUKmvZ"
					:style="`background-position: 0px 0px;background-image: url(${config.jackpot_image});background-size: 100% 100%;`">
					<CountTo :startVal='startVal'  @getDisplayValue="getDisplayValue" :endVal='BonusAmount' :decimals='2'
						:duration='5000' />
					<div slot="defaultValue" class="defaultValue"
						style="margin-top: .4rem;display: flex;align-items: center;--jackpot-card-count-box-scale:0.6;transform: scale(var(--jackpot-card-count-box-scale));">
						<p v-for="(item,index) in BonusTrendsAmount" style="display: flex;height: .0.83rem;">
							<i v-if="item=='.'" :style="{
							  	display:'inline-block',
							  	width:'0.36rem',
							  	height: '0.83rem',
							  	backgroundImage:`url(${_coloImg})`,
							  	backgroundPosition:`-11.6rem -10.76rem`,
							  	backgroundSize: '14.52rem 14.17rem'
							  }"></i>
							<i v-else-if="item==','" :style="{
									display:'inline-block',
									width:'0.36rem',
									height: '0.83rem',
									backgroundImage:`url(${_coloImg})`,
									backgroundPosition:`-13.94rem 0rem`,
									backgroundSize: '14.52rem 14.17rem'
								}"></i>
							<i v-else :style="{
								display:'inline-block',
								width:'0.53rem',
								height: '0.83rem',
								backgroundImage:`url(${_coloImg})`,
								backgroundPosition:`${'-'+(item*0.55+2.75)}rem -12.31rem`,
								backgroundSize: '14.52rem 14.17rem'
							}"></i>
						</p>
					</div>
				
				</div>
			</div>
			<div class="tabs conter_box" 
			 :class="{
				 isTabsShow:isTabsShow,
			 }"
			 style="padding-bottom:1rem">
				<van-tabs v-model="$store.state.active" @change="tabFun"
				 ref="navTbaSwConte"
				 @disabled="onClickDisabled" scrollspy sticky
					:offset-top="`${$store.state.xiazApp?.9+.7:.9}rem`" @scroll="scrollTabs">
					<div  slot="nav-left-right"  class="tabForm-box">
						<div  v-if="tabForm.leftRight!=0" @click.stop="navTbaSw('left')" tabkey="left" class="LLCEvsnIyNozmsBiz0sD leftButton">
							<svg-icon icon-class="img_scroll_jt--svgSprite:all" />
						</div>
						<div  v-if="tabForm.leftRight!=1" @click.stop="navTbaSw('right')" tabkey="right" class="xQxdPR5O2U8rhaYlHoeQ rightButton">
							<svg-icon icon-class="img_scroll_jt--svgSprite:all" />
						</div>
					</div>
					<van-tab v-for="(item,index) in iconList" :key="index" :name="index" :disabled="item.textName=='_Demo'">
						<div slot="title" class="h5MidMenuTab" v-if="item.textName" :name="index">
							<svg-icon v-if="$store.state.active!=index" className="imageWrap" :icon-class="item.svg" />
							<img v-else class="imageWrap" :src="activeList[index]" alt="">
							<div class="N4LsWhJBhqqyTkv6V0NE game-category-name"
								style="margin-bottom: -1.46824px;padding-bottom: 1.46824px;"> {{$t('popup')[item.textName]}}
							</div>
						</div>
						<div class="vwuYKuCNAmUF0GsyJhVu" v-if="tabNav[item.name] && stateActive">
							<div class="kQtvRRirB33JTKwlSb26 titleWrap">
								<div class="leftTitle">
									<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[index]" alt="">
									{{$t('popup.nav')[index].title}}
								</div>
							</div>
							<div class="gameItemBox">
								<gameItem v-for="(item_a,index_1) in tabNav[item.name].list" :category="item.name"
									:item="item_a" type="home" :key="index_1"></gameItem>
								<div style="display: flex;justify-content: center;width: 100%;">
									<van-empty v-if="!tabNav[item.name].list.length" image-size="106px" class="custom-image"
										:image="img_none_sj" description="Nenhum jogo disponível" />
								</div>
								
							</div>
							<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(item.name)"
								v-if="tabNav[item.name].now<tabNav[item.name].count">
								{{$t('_home._Aexibir')}} {{tabNav[item.name].now}} {{ $t('_home._jogosentre') }}
								{{tabNav[item.name].count}}{{$t('_home._Popularjogos') }}
								<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">{{ $t('_home._Carregarmais') }}
									<svg-icon className="imageWrap_s" style="width: .14rem;height: .14rem;vertical-align: unset;"
										icon-class="comm_icon_jzgd--svgSprite:all" />
								</div>
							</div>
						</div>
						
						
						<!-- <div v-else-if="iconList[$store.state.active].textName=='_Recente'">
							<gameItem v-for="(item_a,index_1) in tabNav.popular.list"
								:item="item_a" type="home" :key="index_1"></gameItem>
							<van-empty image-size="106px" class="custom-image" :image="img_none_sj"
								description="Nenhum jogo disponível" />
						</div> -->
					</van-tab>
				</van-tabs>
				
				<div class="vwuYKuCNAmUF0GsyJhVu" v-if="iconList[$store.state.active].textName=='_Recente'">
					<div class="kQtvRRirB33JTKwlSb26 titleWrap">
						<div class="leftTitle">
							<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[$store.state.active]" alt="">
							{{$t('popup.nav')[4].title}}
						</div>
					</div>
					<div class="gameItemBox">
						<gameItem v-for="(item_a,index_1) in recentJson.list"
							:item="item_a" type="home" :key="index_1"></gameItem>
							<div style="display: flex;justify-content: center;width: 100%;">
								<van-empty v-if="!recentJson.list.length" image-size="106px" class="custom-image"
									:image="img_none_sj" description="Nenhum jogo disponível" />
							</div>
					</div>
					<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(iconList[$store.state.active].name)"
						v-if="recentJson.now< recentJson.count">
						{{$t('_home._Aexibir')}} {{recentJson.now}} {{ $t('_home._jogosentre') }}
						{{recentJson.count}}{{$t('_home._Popularjogos') }}
						<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">{{ $t('_home._Carregarmais') }}
							<svg-icon className="imageWrap_s" style="width: .14rem;height: .14rem;vertical-align: unset;"
								icon-class="comm_icon_jzgd--svgSprite:all" />
						</div>
					</div>
				</div>
				
				<div class="vwuYKuCNAmUF0GsyJhVu" v-if="iconList[$store.state.active].textName=='_Favoritos'">
					<div class="kQtvRRirB33JTKwlSb26 titleWrap">
						<div class="leftTitle">
							<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[$store.state.active]" alt="">
							{{$t('popup.nav')[5].title}}
						</div>
					</div>
					<div class="gameItemBox">
						<gameItem v-for="(item_a,index_1) in favoriteJson.list"
							:item="item_a" type="home" :key="index_1"></gameItem>
							<div style="display: flex;justify-content: center;width: 100%;">
								<van-empty v-if="!favoriteJson.list.length" image-size="106px" class="custom-image"
									:image="img_none_sj" description="Nenhum jogo disponível" />
							</div>
					</div>
					<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(iconList[$store.state.active].name)"
						v-if="favoriteJson.now< favoriteJson.count">
						{{$t('_home._Aexibir')}} {{favoriteJson.now}} {{ $t('_home._jogosentre') }}
						{{favoriteJson.count}}{{$t('_home._Popularjogos') }}
						<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">{{ $t('_home._Carregarmais') }}
							<svg-icon className="imageWrap_s" style="width: .14rem;height: .14rem;vertical-align: unset;"
								icon-class="comm_icon_jzgd--svgSprite:all" />
						</div>
					</div>
				</div>
				
				<!-- <div v-if="iconList[$store.state.active].textName=='_Favoritos'">
					<van-empty image-size="106px" class="custom-image" :image="img_none_sj"
						description="Nenhum jogo disponível" />
				</div> -->
			
				<div class="LseXQWJMvDiV2XalL3sA">
					<div class="bUGFPYZ0IBtUg6TvqFnc vertival-box">
						<div class="UJY0E549iXw8BAops2og item-box">
							<div class="JxE2bCrlce_jVHNQ56UL link-list-title">{{ $t('_home._Cassino') }}</div>
							<div class="dIvaBEC9yHdlaEHxlYwe">
								<div @click="openUrl('event')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Eventos') }}</div>
								<div class="m4LhLKEZzJw_JjBFp0PP link-list-item ImbZfuNlIcC1JuS98DxH">
									{{ $t('_home._Missão') }}</div>
								<div class="m4LhLKEZzJw_JjBFp0PP link-list-item ImbZfuNlIcC1JuS98DxH">
									{{ $t('_home._Rebate') }}</div>
								<div @click="openUrl('canReceive')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Pendente') }}</div>
								<div @click="openUrl('vip')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._VIP') }}</div>
								<div @click="openUrl('promote')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Convidar') }}</div>
								<div @click="openUrl('rechargeFund')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._FundodePrevidência') }}</div>
								<!--  -->
							</div>
						</div>
						<!--
				'_Cassino:"Cassino",_Jogos:"Jogos",_Suporte:"Suporte",
				 -->
						<div class="UJY0E549iXw8BAops2og item-box">
							<div class="JxE2bCrlce_jVHNQ56UL link-list-title">{{ $t('_home._Jogos') }}</div>
							<div class="dIvaBEC9yHdlaEHxlYwe">
								<div @click="" class="m4LhLKEZzJw_JjBFp0PP link-list-item">{{ $t('_home._Slots') }}</div>
								<div class="m4LhLKEZzJw_JjBFp0PP link-list-item">{{ $t('_home._Pescaria') }}</div>
								<div v-if="!_vuex_tokenIs" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Demo') }}</div>
							</div>
						</div>
						<div class="UJY0E549iXw8BAops2og item-box">
							<div class="JxE2bCrlce_jVHNQ56UL link-list-title">{{ $t('_home._Suporte') }}</div>
							<div class="dIvaBEC9yHdlaEHxlYwe">
								<div @click="openUrl('notice')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._Suporteonline') }}</div>
								<div @click="openUrl('notice')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">
									{{ $t('_home._CentraldeAjuda') }}</div>
								<!-- <div @click="openUrl('notice')" class="m4LhLKEZzJw_JjBFp0PP link-list-item">{{ $t('_home._BônusdeSugestão') }}</div> -->
							</div>
						</div>
			
					</div>
			
					<!--  -->
					<div class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap contactus-box">
						<div class="ant-spin-container">
							<div class="dX6cyFScnFIBaOgvBaOs contact-us-title">{{ $t('_home._Contatenos') }}</div>
							<div class="M5NoRqtHOQuocfpNLEaP contact-us">
								<div v-if="$store.state.config.cnpj_link != '@'" class="ZRKW0CIFYaJACLzrm8Rh" @click="goToNewPage($store.state.config.cnpj_link)">
									<img src="./../../assets/kuaijiexiaz/cnpj.png" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;">
								</div>
								<div class="ZRKW0CIFYaJACLzrm8Rh" @click="goToNewPage($store.state.config.telegram)">
									<img src="./../../assets/kuaijiexiaz/telegram.png" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;">
								</div>
								<div v-if="$store.state.config.cnpj_link == '@'" class="ZRKW0CIFYaJACLzrm8Rh" @click="goToNewPage($store.state.config.facebook)">
									<img src="./../../assets/kuaijiexiaz/Facebook.png" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;">
								</div>
								<img src="./../../assets/kuaijiexiaz/18plus.webp" class="goDRiiBsuEuXD3W1NphN"
									style="width: 0.7rem; height: 0.7rem;">
							</div>
						</div>
					</div>
					<!--  -->
					<div class="company-info">
						<p v-html="$store.state.config.explain_description"></p>
					</div>
					<!--  -->
					<div class="TNhhMltbvlGJmWgLd9F5 logo-area" style="padding-bottom: 0.5rem;">
						<img src="./../../assets/kuaijiexiaz/1787895843620151297.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787895866936729601.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787895894733852673.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787895923944112129.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787895973235789826.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787895996923097090.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787896020621692929.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787896065595138049.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787896105886920705.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787896145486123010.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
						<img src="./../../assets/kuaijiexiaz/1787896184165216257.png" class="goDRiiBsuEuXD3W1NphN"
							style="height: 0.38rem;">
					</div>
					<!--  -->
					<div class="ZvyDsn48oSuCoga63jmJ copy-right">
						<div class="ZvyDsn48oSuCoga63jmJ" style="padding-bottom: .1rem;    font-size: .18rem;text-align: center;">
							<ul>
								<li style="width: 50%;">
									<p class="">
									<p style="font-size: .18rem;">{{$store.state.config.project_name}}</p>
									</p>
								</li>
								<li style="width: 50%;">
									<p style="font-size: .18rem;" v-html="$store.state.config.copyright"></p>
								</li>
			
							</ul>
						</div>
					</div>
				</div>
				<!--  -->
			</div>
			
			<Sidebar></Sidebar>
		</div>
		
	</div>


</template>

<script>
	// @ is an alias to /src
	import Sidebar from "@/components/sidebar/index.vue"
	import Swipe from "@/components/swipe/index.vue"
	import {
		ICON_NAV,
		ICON_NAVActive
	} from "@/assets/public/icon_nav.js"
	import Overlay from "@/components/audio/overlay.vue"
	import gameItem from "@/components/gameItem/index.vue"
	import CountTo from '@/components/vue-count-to/vue-countTo.vue'
	import advertisementHome from "@/components/advertisement/home.vue"
	import sidebars from "@/components/sidebars/index.vue"
	import {
		mapState
	} from 'vuex';
	let time;
	import Vue from 'vue'
	import {
		baseConfig,
		popAndBanner,
		notification,
		jackpot,
		getAllGameCategory,
		getDataByGameCategory
	} from "@/apis/modules/home.js"
	export default {
		name: 'Home',
		components: {
			Sidebar,
			Swipe,
			Overlay,
			sidebars,
			CountTo,
			gameItem,
			advertisementHome,
		},
		computed: {
			...mapState(['popList','tabNav','banner','sidebarsList','marquee','config','isCancelDisplay'])
		},
		data() {
			return {
				tongzhi: require('./../../assets/icons/images/icon_dt_pmd.png'),
				coloImgactivity: require('./../../assets/icons/images/1795312712372871170.gif'),
				startVal: 0,
				iconList: [],
				stateActive:true,
				widUrl:window.location.href,
				activeList: ICON_NAVActive,
				base_Config: {},
				BonusAmount: '',
				BonusTrendsAmount: '',
				recentJson:{},
				favoriteJson:{},
				thisiTime:'',
				tabState: false,
				_vuex_tokenIs: false,
				scrollDom:"",
				isTabsShow:false,
				tabForm:{
					leftRight:0,
				},
			}
		},
		created() {
			this.inviteFun();
			if (this._vuex_token && typeof this._vuex_token() == 'string') {
				this.getBalance();
				this._vuex_tokenIs = true;
				this.iconList = ICON_NAV.filter((item, index) => {
					return item.textName != '_Demo'
				})
				this.activeList = ICON_NAVActive.filter((item, index) => {
					return index != 3
				})
			} else {
				this.iconList = ICON_NAV
			}
			let inviteTime=localStorage.getItem('inviteTime');
			if(inviteTime && this.popList.length){
				if(inviteTime>new Date().getTime()){
					this.$store.commit('publicFun',['isCancelDisplay',false])
				}else{
					this.$store.commit('publicFun',['isCancelDisplay',true])
				}
			}
			this.getpopAndBanner();
			this.getNotification();
			this.getAllGameCategory();
			this.showRegisterPage();
		},
		mounted() {
			Vue.prototype.homeScrollbarDom = this.$refs.homeScrollbar;
			this.$nextTick(() => {
			  let el=this.$refs.navTbaSwConte.$el;
			  let scrollDom=el.getElementsByClassName('van-tabs__nav--complete')[0];
			  this.scrollDom=scrollDom;
			  let offsetWidth=scrollDom.offsetWidth;
			  let scrollWidth= scrollDom.scrollWidth;
			  let scrollJisuan=scrollWidth-offsetWidth;
			  let {leftRight}=this.tabForm;
			  scrollDom.addEventListener('scroll', (e)=>{
				  if(scrollJisuan<=scrollDom.scrollLeft){
					  this.tabForm.leftRight=1
				  }else if(scrollDom.scrollLeft==0){
					  this.tabForm.leftRight=0
				  }else if(leftRight!=2 && scrollDom.scrollLeft!=0){
					  this.tabForm.leftRight=2
				  }
			  });
			});
			this.getjackpot(0);
		},
		beforeDestroy() {
			clearTimeout(this.thisiTime);
		},
		methods: {
			CancelDisplay(e){
				this.$store.commit('publicFun',['isCancelDisplay',false])
				if(e){
					window.localStorage.setItem('inviteTime',(new Date()).getTime()+24*60*60*1000)
				}
			},
			showRegisterPage() {
				if(this._vuex_tokenIs || !this.$store.state.config.register_page_pop)return;
				this.$store.commit('isRecord');
				this.$store.commit('publicFun',['isRecordType',0]);
			},
			inviteFun(){
				let {id,type}=this.$route.query;
				//console.log(this.$route.query,id,type,'inviteID 22')
				if(id){
					window.localStorage.setItem('inviteID',id)
				}
				// this.$store.commit('isRecord')
				// this.$store.commit('publicFun',['isRecordType',0])
			},
			navTbaSw(e){
				if(e=='right'){
					this.scrollDom.scrollLeft=1000
				}
				if(e=='left'){
					this.scrollDom.scrollLeft=0
				}
			},
			beforeChange(name,title){//
				
			},
			scrollTabs(e) {
			},
			handleScroll(e) {
				clearTimeout(time);
				time = setTimeout(() => {
					clearTimeout(time);
					if (e.target.scrollTop > (e.target.offsetHeight/3+59)) {
						this.$store.commit('publicFun', ['Returntotop', true])
					} else if (this.$store.state.Returntotop) {
						this.$store.commit('publicFun', ['Returntotop', false])
					}
					if(e.target.scrollTop>(e.target.scrollHeight-e.target.offsetHeight-20)){
						this.isTabsShow=true
					}else{
						this.isTabsShow=false
					}
				}, 10)
			},
			isDisabled(index) {
				let isTrue = false;
				let active = this.$store.state.active;
				if (index == 3) {
					isTrue = true;
					return true;
				}
				if (active < 3) {
					if (index < 3) {
						return false;
					}
					if (index > 3) {
						isTrue = false;
						return true;
					}
				}
				if (active > 3) {
					if (index < 3) {
						isTrue = true;
						return true;
					}
					if (index > 3) {
						isTrue = false;
						return false;
					}
				}
				return isTrue;
			},
			getAllGameCategory() {
				if(this.tabNav.popular){
					return false;
				}
				getAllGameCategory({}).then(({
					code,
					data = {},
					msg
				}) => {
					this.$store.commit('publicFun', ['tabNav', data])
				})
			},
			goToNewPage(url) {
				if (url == '#') {
					return false;
				}
				window.open(url, '_blank');
			},
			congxJiazai(category,_key_) {
				let pageSize = category == 'popular' && this.$store.state.config.popular_page_size ? this.$store.state.config.popular_page_size : 6;
				let page=this.tabNav[category] ? this.tabNav[category].now/pageSize+1 : 1;
				//console.log(_key_)
				if(_key_){page=this[_key_+'Json'].now ? this[_key_+'Json'].now/pageSize+1 : 1};
				getDataByGameCategory({
					category: category,
					page: page
				}).then(({
					code,
					data = {},
					msg
				}) => {
					if(!_key_){
						this.tabNav[category] ={
							...this.tabNav[category],
							now: data.now,
							list: [...this.tabNav[category].list, ...data.list]
						}
						this.$store.commit('publicFun', ['tabNav',this.tabNav])
					}else{
						let listz=(this[_key_+'Json'].list?[...this[_key_+'Json'].list, ...data.list]:[...data.list]);
						this[_key_+'Json']={
							...this[_key_+'Json'],
							now:data.now,
							list:listz,
						}
					}
					
				})
			},
			getDisplayValue(value) {
				this.BonusTrendsAmount = value;
			},
			getpopAndBanner() { //
				if(this.banner.length){
					return false;
				}
				popAndBanner({}).then(({
					code,
					data = {},
					msg
				}) => {
					let {
						banner,
						pop,
						sidebars
					} = data;
					this.$store.commit('publicFun', ['banner', banner])
					// this.banner = banner;
					if (!this.popList.length) {
						this.$store.commit('popList', pop.map((item) => {
							return {
								...item,
								isShow: true,
							}
						}));
					}
					this.$store.commit('publicFun', ['sidebarsList',  sidebars.map((item) => {
						return {
							...item,
							isShow: true,
						}
					})])
				})
			},
			
			getNotification() {
				if(this.marquee){
					return false;
				}
				notification({}).then(({
					code,
					data,
					msg
				}) => { //404
					let {
						marquee
					} = data;
					this.$store.commit('publicFun', ['marquee', marquee])
				})
			},
			getjackpot(tim,_false) {
				if (!this.$store.state.config.show_jackpot)return;
				// if(_false){return false}
				clearTimeout(this.thisiTime);
				this.thisiTime = setTimeout(() => {
					
					jackpot({
						isLOgin: true,
					}).then(({
						code,
						data,
						msg
					}) => {
						this.startVal=data-1999||0
						this.BonusAmount = data || 0;
					})
					if(this.$route.name=='home'){
						this.getjackpot(10000)
					}
					let {code,category}=this.$route.query;
				}, tim)
			},
			onClickDisabled(e) {
				this.$router.push({
					name: 'subgame',
					query: {
						id: '',
						category: 'demo',
					}
				})
			},
			tabFun(e,title) {
				if(e<3){
					this.stateActive=true;
				}else{
					this.stateActive=false;
				}
				
				// this.$store.commit('active', e)
				let category=this.iconList[e].name;
				if(category=='recent'){
					this.congxJiazai(category,category)
					this[category+'Json']={}
				}
				// this.$store.commit('publicFun',['isRecordType',e]);
				if(category=='favorite'){
					this.congxJiazai(category,category)
					this[category+'Json']={}
				}
				setTimeout(()=>{
					this.$store.commit('active', e)
				},1000)
			},
			stickyScroLL(e) {}
		}
	}
</script>
<style scoped>
	/* // isTabsShow van-sticky van-sticky--fixed */
	
	.tabs .imageWrap {
		margin-left: -0.1rem;
	}

	.imageWrap_s {
		margin-left: 0.05rem;
	}
	.leftButton,.rightButton {
	    background-color: rgba(0,0,0,.1);
	    border: .01rem solid hsla(0,0%,100%,.1);
	    border-radius: .18rem;
	    color: #fff;
	    cursor: pointer;
	    height: .36rem;
	    line-height: .36rem;
	    text-align: center;
	    top: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    width: .36rem;
		min-width: .36rem;
	}
	.leftButton svg,.rightButton svg{
		width: .15rem;
		height: .15rem;
	}
	.tabForm-box{
		width: 100%;height: 0px;display: flex;align-items: center;
		justify-content: space-between;
		position: absolute;
		top:.55rem;
		z-index: 10;
	}
	.leftButton{
		position: absolute;
		left: 0;
		transform: translateY(-50%) rotate(180deg);
	}
	.tabs  .van-tabs__content{
		position: relative;
	}
	.rightButton{
		position: absolute;
		right: 0;
	}
</style>
<style>
	.isTabsShow .van-sticky{
		opacity: 0;
	}
	.van-sticky.van-sticky--fixed {
		transform: initial;
		transition: .5s;
	}

	.conter_box .van-tab--disabled {
		color: #646566 !important;
		cursor:pointer;
	}
	.conter_box .van-tab{
		padding: 0;
	}
	
	.home-scrollbar .van-tab__text--ellipsis {
		-webkit-line-clamp: initial;
	}

	.home-scrollbar .van-sticky--fixed {
		z-index: 88;
	}

	.home-scrollbar {
		height: 100vh;
		/* overflow: scroll; */
	}
	.conterTeng-scrollbar{
		overflow: scroll;
	}


	.conterTeng-scrollbar::-webkit-scrollbar {
	    width: .06rem;
		height: 1rem;
		display: block;
		position: relative;
	}
	 
	/* 自定义滚动条轨道 */
	.conterTeng-scrollbar::-webkit-scrollbar-track {
	  background:transparent; /* 设置轨道的背景颜色 */
	}
	 
	/* 自定义滚动条的滑块（thumb） */
	.conterTeng-scrollbar::-webkit-scrollbar-thumb {
	  background: var(--theme-color-line); /* 设置滑块的背景颜色 */
	  
	}
	 
	/* 当滑块悬停或活动时自定义滚动条的滑块 */
	.conterTeng-scrollbar::-webkit-scrollbar-thumb:hover {
	  background: #555; /* 设置滑块在悬停状态下的背景颜色 */
	}
	  .conterTeng-scrollbar {
		-ms-overflow-style:scroll; /* 针对IE、Edge浏览器 */
		scrollbar-width: auto; /* 针对Firefox浏览器 */
	  }

	.marquee {
		background-color: transparent;
		border-radius: 0;
		-webkit-box-shadow: 0 .03rem .09rem 0 transparent;
		box-shadow: 0 .03rem .09rem 0 transparent;
		font-size: .24rem !important;
		padding: 0 .2rem 0 .25rem;
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		justify-content: center;
		background-color: var(--theme-main-bg-color) !important;
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		color: var(--theme-text-color) !important;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 !important;
	}

	.van-hairline--bottom::after {
		border: none !important;
	}

	.marquee .van-icon {
		display: flex;
		justify-content: center;
		align-content: center;
		margin-right: 5px;
	}

	.notice-bar {
		display: flex;
		width: 100%;
		align-items: center;
		box-sizing: border-box;
		padding: .06rem .2rem !important;
		 cursor: pointer;
	}

	.zhtDEqmqr6aOwFvu_UTp {
		margin: 0 .2rem;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: center;
	}

	._VQdc8vOEOzzyOEUKmvZ {
		cursor: pointer;
		height: var(--jackpot-card-height);
		margin: var(--jackpot-card-margin);
		position: relative;
		width: var(--jackpot-card-width);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.defaultValue {
		justify-content: center;
	}

	.tabs {
		height: 100%;
	}

	.vwuYKuCNAmUF0GsyJhVu {
		padding: 0 0.2rem;
	}

	.gameItemBox {
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		height: 100%;
	}

	.scroll-container {
		height: 100%;
		overflow: auto;
	}

	.tabs .van-tab {
		line-height: normal;
	}

	.tabs .van-tabs--line .van-tabs__wrap {
		height: auto !important;
	}

	.tabs .van-tabs .van-tabs__nav {
		background-color: var(--theme-home-bg);
		border-bottom: .01rem solid var(--theme-color-line);
		height: 1.06rem;
		padding: .08rem 0 0;
	}
	

	.tabs .van-tabs__line {
		background-color: var(--theme-primary-color);
		border-radius: .04rem .04rem 0 0;
		height: .04rem;
		min-width: 1.5rem;
		bottom: 0;
	}

	.tabs .van-tab--active .h5MidMenuTab {
		color: var(--theme-primary-color) !important;
	}

	.tabs .cover {
		background: transparent !important;
	}

	.tabs .imageWrap {
		height: .46rem !important;
		width: .6rem !important;
	}

	.h5MidMenuTab {
		min-width: 1.5rem;
		position: relative;
		text-align: center;
		z-index: 2;
		color: var(--theme-alt-neutral-2);
	}

	.game-category-name {
		line-height: 1.5;
		max-width: 1.36rem;
		width: 1.36rem !important;
	}

	.leftTitle {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
	}

	.kQtvRRirB33JTKwlSb26 {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		padding: .3rem 0;
	}

	.bkAYznjEc9hUqGQh_a3S {
		display: block;
		font-size: .24rem;
		text-align: center;
		color: var(--theme-text-color-lighten);
		margin-top: .1rem;
	}

	.WGAruokZzpTWkHI2SQ9G {
		color: var(--theme-text-color);
		display: block;
		font-size: .24rem;
		margin-top: .03rem;
	}

	.LseXQWJMvDiV2XalL3sA {
		padding: .4rem .2rem;
		position: relative;
		width: 100%;
    background-color: var(--theme-home-bg);
		z-index: 1;
		box-sizing: border-box;
	}

	.UJY0E549iXw8BAops2og {
		-ms-flex: 1;
		flex: 1;
		overflow: hidden
	}

	.vertival-box {
		margin-bottom: .4rem;
		padding-bottom: .48rem;
		position: relative
	}

	.vertival-box>div {
		padding-right: .2rem
	}

	.UJY0E549iXw8BAops2og .JxE2bCrlce_jVHNQ56UL {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		margin-bottom: .12rem
	}

	.TxOZ1v6RrtK6SNKSEukb .vertival-box {
		margin-bottom: .4rem;
		padding-bottom: .48rem;
		position: relative
	}

	.bUGFPYZ0IBtUg6TvqFnc {
		display: -ms-flexbox;
		display: flex;
	}

	.link-list-title {
		color: var(--theme-text-color);

		margin-bottom: .2rem
	}

	.vertival-box:after {
		background-color: var(--theme-color-line);
		bottom: 0;
		content: "";
		height: .01rem;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 100%;
	}

	.UJY0E549iXw8BAops2og .dIvaBEC9yHdlaEHxlYwe .m4LhLKEZzJw_JjBFp0PP {
		color: var(--theme-text-color);
		cursor: pointer;
		font-size: .24rem !important;
		margin-bottom: .1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.company-info {
		padding-bottom: .4rem;
		position: relative;
		    text-align: center;
	}

	.contactus-box {
		margin-bottom: .4rem
	}

	.dX6cyFScnFIBaOgvBaOs {
		color: var(--theme-text-color);
		font-size: .24rem;
		font-weight: 400;
		margin-bottom: .2rem
	}

	.ZRKW0CIFYaJACLzrm8Rh {
		box-sizing: border-box;
		height: .7rem;
		display: flex;
	}

	.M5NoRqtHOQuocfpNLEaP {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		box-sizing: border-box;
		gap: .2rem
	}

	.company-info:after {
		background-color: var(--theme-color-line);
		bottom: 0;
		content: "";
		height: .01rem;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 100%
	}

	.company-info {
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
		line-height: 1.66;
		word-break: break-all
	}

	.TNhhMltbvlGJmWgLd9F5 {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		gap: .1rem;
		justify-content: center;
		margin-top: .4rem
	}

	.copy-right {
		padding-top: .4rem
	}

	/* .ZvyDsn48oSuCoga63jmJ ul {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
} */
	.ZvyDsn48oSuCoga63jmJ {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		width: 100%
	}

	.ZvyDsn48oSuCoga63jmJ ul {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		margin: 0 !important;
		width: 100%
	}

	.ZvyDsn48oSuCoga63jmJ ul li {
		border-right: .01rem solid var(--theme-color-line);
		color: var(--theme-text-color-lighten);
		font-size: .16rem;
		line-height: 1.2;
		overflow: hidden;
		padding: 0 .32rem;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.ZvyDsn48oSuCoga63jmJ ul li:last-child {
		border-right: 0
	}

	.N4LsWhJBhqqyTkv6V0NE {}
</style>
