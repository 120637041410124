var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
	height:`${_vm.$store.state.xiazApp?.9+.7:.9}rem`,
	transition: '.3s',
	'background-color':'var(--cms-top-background-color)'
})},[(_vm.$store.state.xiazApp)?_c('div',{staticClass:"v7Gu3VSoEuypS8B9EgaJ",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"aG5W8rsptmZ9RO66S3cI",staticStyle:{"background-color":"var(--b-app-rgba)"}},[_c('div',{staticClass:"pxU3k4yaAHPfT3wK4R8v",staticStyle:{"display":"flex"},on:{"click":function($event){$event.stopPropagation();return _vm.qingCHuApp.apply(null, arguments)}}},[_c('svg-icon',{staticClass:"svg",staticStyle:{"color":"rgb(var(--theme-ant-primary-color-19))","width":".2rem","height":".2rem"},attrs:{"icon-class":"comm_icon_x--svgSprite:all"}})],1),_c('div',{staticClass:"DAEXSUBjuzUY1EoIAeEn LubvNYW6hJ8aFPwvFVVB",staticStyle:{"display":"flex"}},[_c('img',{staticClass:"goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton",staticStyle:{"height":"0.51rem","z-index":"1"},attrs:{"src":_vm.config.home_download_image,"alt":"."}})]),_c('div',{staticClass:"DvuNDsU5DEFvvcIAXWAe",staticStyle:{"display":"flex"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('publicFun',['AppDownload',true])}}},[_vm._m(0)])])]):_vm._e(),_c('div',{staticClass:"QP3BhRt03qv1Gwd20Gjx",style:({
	 			 'background-image':`url(${_vm.config.background_image})`
	 })},[_c('div',{staticStyle:{"display":"flex","align-items":"center","z-index":"9","position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.show_Popup.apply(null, arguments)}}},[_c('svg-icon',{staticClass:"menComes",attrs:{"icon-class":"RbbrBFJf-icon_dt_1cd:all"}})],1),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.config.home_logo),expression:"config.home_logo"}],staticClass:"krx_BqY0Ot5YxLi63BNa",attrs:{"src":_vm.config.home_logo,"loading":"loaded"}}),_c('div',{staticClass:"pendant-I0d9y"},[(_vm.config.festival_img.top_1 != '#')?_c('img',{staticClass:"pendant-left-vQVTe",attrs:{"src":_vm.config.festival_img.top_1,"alt":""}}):_vm._e(),(_vm.config.festival_img.top_2 != '#')?_c('img',{staticClass:"pendant-center-vjDmr",attrs:{"src":_vm.config.festival_img.top_2,"alt":""}}):_vm._e(),(_vm.config.festival_img.top_3 != '#')?_c('img',{staticClass:"pendant-right-PUsnH",attrs:{"src":_vm.config.festival_img.top_3,"alt":""}}):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"ant-btn ant-btn-default",staticStyle:{"background-color":"rgb(var(--theme-ant-primary-color-19))","color":"var(--theme-primary-font-color)","border":"none"},attrs:{"type":"button"}},[_c('span',[_vm._v("Baixar Agora")])])
}]

export { render, staticRenderFns }